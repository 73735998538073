.customer-new-section {
    padding: 100px 0 100px 0;
    background: #F4FAFE;
    min-height: auto !important;

    .customer-content {
        position: unset;
        top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 10px;

        h2 {
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            margin: 0;
            margin-bottom: 8px;
            text-align: center;
        }

        p {
            font-size: 16px;
            line-height: 150%;
            color: #686869;
            font-weight: 400;
            margin: 0;
            text-align: center;
        }
    }

    .customer-right-content {
        // background-image: url(../../../public/images/home/customer-slider.webp);
        width: 100%;
        height: auto;
        // background-size: 100% 100%;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center;
        padding: 30px 0;
        height: auto;
        position: relative;

        &::after {
            content: '';
            height: 100%;
            width: 100px;
            // background: var(--secondary-background-dark-shade);
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 99;
            transform: rotate(-180deg);
            background: linear-gradient(to left, #F4FAFE, transparent);
        }

        &::before {
            content: '';
            height: 100%;
            width: 100px;
            // background: var(--secondary-background-dark-shade);
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 99;
            transform: rotate(0deg);
            background: linear-gradient(to left, #F4FAFE, transparent);
        }

        .swiper {
            width: 100%;
            height: 100%;
            margin-left: 0px;

            .swiper-wrapper {
                height: 100%;
                gap: 30px;
            }

            .swiper-slide {
                margin: 0 auto 20px;
            }

            .testi-box {
                border-radius: 16px;
                border: 2px solid rgba(0, 134, 230, 0.20);
                background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
                box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.04);
                padding: 22px 24px;
                margin: 0 auto;
                min-width: 730px;
                max-width: 730px;
                min-height: 450px;

                .swiper-heading-text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding-bottom: 12px;

                    h3 {
                        font-size: 24px;
                        line-height: 140%;
                        font-weight: 600;
                        color: #040405;
                        margin: 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        max-width: 540px;
                        margin-bottom: 16px;

                        span {
                            display: block;
                            font-size: 14px;
                            line-height: 150%;
                            font-weight: 400;
                            color: #686869;
                            padding-left: 20px;
                            position: relative;

                            &:before {
                                content: "";
                                width: 5px;
                                height: 5px;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                left: 6px;
                                background-color: #686869;
                                border-radius: 100px;
                            }
                        }
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        li {
                            padding-right: 10px;
                            list-style: none;
                            margin-bottom: 20px;

                            img {
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }

                    .slider-heading-right {
                        width: 48px;
                        height: 48px;
                        border-radius: 100px;
                        margin-top: -15px;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #040405;
                    margin: 0 auto;
                    max-width: 540px;
                    text-align: center;
                    // overflow-y: auto;
                    // max-height: 72px;
                    // &::-webkit-scrollbar {
                    //     width: 3px;
                    // }
                    // /* Track */
                    // &::-webkit-scrollbar-track {
                    //     background: var(--webkit-bg);
                    // }
                    // /* Handle */
                    // &::-webkit-scrollbar-thumb {
                    //     background: var(--webkit-bg);
                    // }
                    // /* Handle on hover */
                    // &::-webkit-scrollbar-thumb:hover {
                    //     background: #555;
                    // }
                }



            }

            .swiper-button-prev,
            .swiper-button-next {
                width: 40px;
                height: 40px;
                border-radius: 8px;
                border: 2px solid rgba(0, 134, 230, 0.20);
                background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
                display: flex;
                align-items: center;
                justify-content: center;

            }



            .swiper-button-prev {
                right: 0px;
                left: unset;
                top: 54%;

                &::after {
                    content: "";
                    background-image: url(../../../public/images/home/icn-arrow.svg);
                    background-color: unset;
                    width: 15px;
                    height: 15px;
                    transform: rotate(180deg);
                    background-position: center;

                }
            }

            .swiper-button-next {
                right: 0px;
                top: 45%;

                &::after {
                    content: "";
                    // background-image: url(../../../public/images/home/icn-arrow-dark.svg);
                    background-image: url(../../../public/images/home/icn-arrow.svg);
                    background-color: unset;
                    width: 15px;
                    height: 15px;
                    transform: rotate(0);
                    background-position: center;
                }
            }

            .swiper-button-next.swiper-button-disabled {
                opacity: 1;

                &::after {
                    content: "";
                    background-image: url(../../../public/images/home/icn-arrow-dark.svg);
                    transform: rotate(180deg);
                }
            }

            .swiper-button-prev.swiper-button-disabled {
                opacity: 1;

                &::after {
                    content: "";
                    background-image: url(../../../public/images/home/icn-arrow-dark.svg);
                    transform: rotate(0);
                }
            }
        }
    }
}

.tech-customer-section {
    padding-top: 50px;
    padding-bottom: 140px;
}

.author {
    font-size: 20px;
    font-weight: 550;
    line-height: 22px;
    margin-top: 25px;
    text-align: center;
}

.author-position {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
    color: rgb(90, 90, 90);
}


@media only screen and (max-width: 1400px) and (min-width: 1025px) {
    .tech-customer-section {
        padding-bottom: 0 !important;
    }

    .container {
        max-width: 1000px !important;
    }

    .customer-new-section {
        min-height: 620px !important;
    }

    .customer-new-section .customer-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text h3 {
        font-size: 16px;
        line-height: 20px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text h3 span {
        font-size: 12px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text .slider-heading-right {
        width: 34px;
        height: 34px;
        margin-top: -30px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text .slider-heading-right img {
        width: 100%;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text ul li img {
        width: 14px;
        height: 16px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box p {
        font-size: 12px;
        line-height: 18px;
    }

    .customer-new-section .customer-right-content {
        background-size: 100% 100%;
    }
}

.contact-customer-section {
    padding-top: 90px;
}

@media (max-width: 1400px) and (min-width: 1025px) {
    .customer-new-section .customer-right-content .swiper .swiper-button-next {
        right: 10px;
    }

    .customer-new-section .customer-right-content .swiper .swiper-button-prev {
        right: 10px;
    }
}

@media (max-width: 1400px) {
    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 350px !important;
        min-height: 350px !important;
    }
}

@media (max-width: 1024px) {
    .tech-customer-section {
        padding-top: 0 !important;
    }

    .customer-new-section {
        padding: 80px 0;
        min-height: auto;
    }

    .contact-customer-section {
        padding: 0px 0 80px 0;
    }

    .customer-new-section .customer-right-content .swiper {
        width: 100%;
    }

    .customer-new-section .customer-right-content .swiper .swiper-button-prev,
    .customer-new-section .customer-right-content .swiper .swiper-button-next {
        width: 32px;
        height: 32px;
        right: 6px;
    }

    .customer-new-section .customer-content h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .customer-new-section .customer-content p {
        font-size: 14px;
        line-height: 20px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box {
        padding: 16px 40px 40px 40px;
        min-height: max-content;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text h3 {
        font-size: 16px;
        line-height: 20px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text h3 span {
        font-size: 12px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text .slider-heading-right {
        width: 28px;
        height: 28px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text .slider-heading-right img {
        width: 100%;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text ul li img {
        width: 14px;
        height: 16px;
    }

    .customer-new-section .customer-right-content .swiper .testi-box p {
        font-size: 12px;
        line-height: 18px;
    }

    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
    }

    .customer-new-section .customer-right-content::before,
    .customer-right-content:after {
        display: none;
    }
}

.customer-new-section {
    overflow: hidden;
}

@media (max-width: 992px) {
    .customer-new-section {
        padding: 80px 0;

        .customer-content {
            margin-bottom: 24px;

            h2 {
                font-size: 24px;
                line-height: 34px;
            }
        }

        .customer-right-content .swiper .swiper-wrapper .testi-box {
            padding: 16px;
        }

        .customer-right-content .swiper .swiper-wrapper .testi-box .swiper-heading-text h3 {
            font-size: 18px;
            margin-bottom: 2px;
        }

        .customer-right-content .swiper .swiper-wrapper .testi-box .swiper-heading-text .slider-heading-right {
            width: 34px;
            height: 34px;
        }

        .customer-right-content .swiper .swiper-wrapper .testi-box .swiper-heading-text .slider-heading-right img {
            width: 100%;
        }

        .customer-right-content .swiper .swiper-wrapper .testi-box p {
            font-size: 14px;
            line-height: 20px;
        }

        .customer-new-section .customer-right-content .testi-box {
            // min-width: unset !important;
            // max-width: unset !important;

        }

        .customer-new-section .customer-right-content::before,
        .customer-right-content:after {
            display: none;
        }
    }

    .customer-new-section .customer-right-content {
        height: unset;
        background-size: 100% 100%;
        min-height: 360px;
    }

    .customer-new-section .customer-right-content .swiper .swiper-button-prev,
    .customer-new-section .customer-right-content .swiper .swiper-button-next {
        display: none;
    }

    .contact-customer-section {
        padding-top: 20px;
    }
}

@media (max-width: 767px) {
    .customer-new-section {
        padding: 60px 0;
    }

    .contact-customer-section {
        padding: 0 0 60px 0;
    }

    .customer-new-section .customer-right-content {
        padding: 30px 16px;
    }

    .customer-new-section .customer-content h2 {
        text-align: center;
    }

    .customer-new-section .customer-content p {
        text-align: center;
        font-size: 14px;
    }

    .customer-new-section .customer-right-content .swiper .swiper-wrapper .testi-box .swiper-heading-text h3 span {
        font-size: 10px;
    }

    .customer-new-section .customer-right-content .swiper .swiper-wrapper .testi-box .swiper-heading-text h3 {
        font-size: 14px;
    }

    .customer-new-section .customer-right-content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 360px;
    }

    .customer-new-section .customer-right-content .swiper .swiper-wrapper .testi-box p {
        height: unset;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }

    .customer-new-section .customer-right-content .swiper .swiper-slide {
        max-width: 100%;
    }

    .customer-new-section .customer-right-content .swiper .testi-box .swiper-heading-text h3 {
        font-size: 13px;
        line-height: 18px;
    }

    .customer-new-section .customer-right-content {
        background-size: 100% !important;
        padding: 0 15px;
        margin-top: -30px;
    }

    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
    }

    .customer-new-section .customer-right-content::before,
    .customer-right-content:after {
        display: none;
    }
}

.slider-heading-right {
    padding-top: 30px;

    img {
        width: 66px !important;
        height: 48px !important;
        position: absolute;
        top: 20px;
    }
}

@media(max-width: 992px) {
    .customer-new-section {
        min-height: unset !important;
    }

    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 400px !important;
        min-height: 400px !important;
    }
}

@media(max-width: 767px) {
    #home-swiper {
        min-height: unset !important;
    }

    .slider-heading-right {
        padding-top: 30px;


        img {
            width: 36px !important;
            height: 44px !important;
            position: absolute;
            top: 20px;
        }
    }

    .customer-new-section .customer-right-content {
        margin-top: 0 !important;
    }

    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 450px !important;
        min-height: 450px !important;
    }
}

@media(max-width: 380px) {
    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 470px !important;
        min-height: 470px !important;
        padding: 20px 30px 30px 30px !important;
    }
}


@media(max-width: 330px) {
    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 515px !important;
        min-height: 515px !important;
        padding: 20px 25px !important;
    }
}

@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 350px !important;
        min-height: 350px !important;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .customer-new-section .customer-right-content .testi-box {
        // min-width: unset !important;
        // max-width: unset !important;
        max-height: 450px !important;
        min-height: 450px !important;
    }
}


.swiper-button-next, .swiper-button-prev {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: 2px solid rgba(0, 134, 230, 0.20);
    background: linear-gradient(132deg, #FFF 0%, rgba(255, 255, 255, 0.90) 100%);
    display: flex;
    top: calc(50% - 20px) !important;
    z-index: 99;
    position: absolute;
    align-items: center;
    justify-content: center;
    box-shadow: 0 7.47px 5.97px 0 rgba(0, 0, 0, .028);
    box-shadow: 0 30px 50px 0 rgba(0, 0, 0, .04);
}


.swiper-button-next{
    right: 17%;
    align-items: center;
    justify-content: center;
    display: flex;
    &::after {
        content: "";
        background-image: url(../../../public/images/home/icn-arrow.svg);
        background-color: unset;
        width: 15px;
        height: 15px;
        transform: rotate(90deg) !important;
        background-position: center center;
        filter: grayscale(100%);
        align-items: center;
        justify-content: center;
        display: flex;
    }
}

.swiper-button-prev {
    left: 17%;
    align-items: center;
    justify-content: center;
    display: flex;
    &::after {
        content: "";
        background-image: url(../../../public/images/home/icn-arrow.svg);
        background-color: unset;
        width: 15px;
        height: 15px;
        transform: rotate(-90deg) !important;
        background-position: center;
        filter: grayscale(100%);
        align-items: center;
        justify-content: center;
        display: flex;
    }
}



@media (max-width: 1024px) {


    .swiper-button-prev {
        left: 14%;
    }
    .swiper-button-next {
        right: 14%;
    }
}

@media (max-width: 992px) {


    .swiper-button-prev {
        left: 1%;
    }
    .swiper-button-next {
        right: 1%;
    }
}

@media (max-width: 767px) {

    .swiper-button-next, .swiper-button-prev {
        width: 40px;
        height: 40px;
    }
    .swiper-button-prev {
        left: 3%;
    }
    .swiper-button-next {
        right: 3%;
    }
}
